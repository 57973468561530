export const SUPERADMIN_GET_USERS = "SUPERADMIN_GET_USERS";
export const SUPERADMIN_ADD_USER = "SUPERADMIN_ADD_USER";
export const SUPERADMIN_UPDATE_USER = "SUPERADMIN_UPDATE_USER";
export const SUPERADMIN_DELETE_USER = "SUPERADMIN_DELETE_USER";

export const SUPERADMIN_GET_BRANCHES = "SUPERADMIN_GET_BRANCHES";
export const SUPERADMIN_ADD_BRANCH = "SUPERADMIN_ADD_BRANCH";
export const SUPERADMIN_UPDATE_BRANCH = "SUPERADMIN_UPDATE_BRANCH";
export const SUPERADMIN_DELETE_BRANCH = "SUPERADMIN_DELETE_BRANCH";

// USER
export function superAdminGetUsers(payload) {
  return { type: SUPERADMIN_GET_USERS, payload };
}
export function superAdminAddUser(payload) {
  return { type: SUPERADMIN_ADD_USER, payload };
}
export function superAdminUpdateUser(payload) {
  return { type: SUPERADMIN_UPDATE_USER, payload };
}
export function superAdminDeleteUser(payload) {
  return { type: SUPERADMIN_DELETE_USER, payload };
}

// BRANCH
export function superAdminGetBranches(payload) {
  return { type: SUPERADMIN_GET_BRANCHES, payload };
}
export function superAdminAddBranch(payload) {
  return { type: SUPERADMIN_ADD_BRANCH, payload };
}
export function superAdminUpdateBranch(payload) {
  return { type: SUPERADMIN_UPDATE_BRANCH, payload };
}
export function superAdminDeleteBranch(payload) {
  return { type: SUPERADMIN_DELETE_BRANCH, payload };
}
