import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";
import { APP_ENV } from "../config";

let store;
if (APP_ENV === "development") {
  store = createStore(reducers, composeWithDevTools());
} else {
  store = createStore(reducers);
}
export default store;
