export const ADMIN_GET_CATEGORIES = "ADMIN_GET_CATEGORIES";
export const ADMIN_ADD_CATEGORY = "ADMIN_ADD_CATEGORY";
export const ADMIN_UPDATE_CATEGORY = "ADMIN_UPDATE_CATEGORY";
export const ADMIN_DELETE_CATEGORY = "ADMIN_DELETE_CATEGORY";

export const ADMIN_GET_SUB_CATEGORIES = "ADMIN_GET_SUB_CATEGORIES";
export const ADMIN_ADD_SUBCATEGORY = "ADMIN_ADD_SUBCATEGORY";
export const ADMIN_UPDATE_SUBCATEGORY = "ADMIN_UPDATE_SUBCATEGORY";
export const ADMIN_DELETE_SUBCATEGORY = "ADMIN_DELETE_SUBCATEGORY";

export const ADMIN_GET_PRODUCTS = "ADMIN_GET_PRODUCTS";
export const ADMIN_ADD_PRODUCT = "ADMIN_ADD_PRODUCT";
export const ADMIN_UPDATE_PRODUCT = "ADMIN_UPDATE_PRODUCT";
export const ADMIN_DELETE_PRODUCT = "ADMIN_DELETE_PRODUCT";

export const ADMIN_GET_USERS = "ADMIN_GET_USERS";

export const ADMIN_GET_BRANDS = "ADMIN_GET_BRANDS";
export const ADMIN_ADD_BRAND = "ADMIN_ADD_BRAND";
export const ADMIN_UPDATE_BRAND = "ADMIN_UPDATE_BRAND";
export const ADMIN_DELETE_BRAND = "ADMIN_DELETE_BRAND";

export const ADMIN_GET_FEATUREDS = "ADMIN_GET_FEATUREDS";
export const ADMIN_ADD_FEATURED = "ADMIN_ADD_FEATURED";
export const ADMIN_UPDATE_FEATURED = "ADMIN_UPDATE_FEATURED";
export const ADMIN_DELETE_FEATURED = "ADMIN_DELETE_FEATURED";

export const ADMIN_GET_PROMOTIONS = "ADMIN_GET_PROMOTIONS";
export const ADMIN_ADD_PROMOTION = "ADMIN_ADD_PROMOTION";
export const ADMIN_UPDATE_PROMOTION = "ADMIN_UPDATE_PROMOTION";
export const ADMIN_DELETE_PROMOTION = "ADMIN_DELETE_PROMOTION";

export const ADMIN_GET_ORDERS = "ADMIN_GET_ORDERS";
export const ADMIN_UPDATE_ORDER = "ADMIN_UPDATE_ORDER";
export const ADMIN_DELETE_ORDER = "ADMIN_DELETE_ORDER";

export const ADMIN_GET_INVENTORY = "ADMIN_GET_INVENTORY";
export const ADMIN_UPDATE_INVENTORY = "ADMIN_UPDATE_INVENTORY";
export const ADMIN_GET_INVENTORY_ITEM = "ADMIN_GET_INVENTORY_ITEM";

export const ADMIN_GET_PROFIT_PRODUCTS = "ADMIN_GET_PROFIT_PRODUCTS";
export const ADMIN_GET_PROFIT_ORDERS = "ADMIN_GET_PROFIT_ORDERS";
export const ADMIN_GET_PROFIT_USERS = "ADMIN_GET_PROFIT_USERS";

export const ADMIN_GET_CONTACT_FORMS = "ADMIN_GET_CONTACT_FORMS";
export const ADMIN_DELETE_CONTACT_FORM = "ADMIN_DELETE_CONTACT_FORM";

export const ADMIN_GET_BLOGS = "ADMIN_GET_BLOGS";
export const ADMIN_ADD_BLOG = "ADMIN_ADD_BLOG";
export const ADMIN_UPDATE_BLOG = "ADMIN_UPDATE_BLOG";
export const ADMIN_DELETE_BLOG = "ADMIN_DELETE_BLOG";

export const ADMIN_GET_SLIDERS = "ADMIN_GET_SLIDERS";
export const ADMIN_ADD_SLIDER = "ADMIN_ADD_SLIDER";
export const ADMIN_UPDATE_SLIDER = "ADMIN_UPDATE_SLIDER";
export const ADMIN_DELETE_SLIDER = "ADMIN_DELETE_SLIDER";

export const ADMIN_GET_TRANSACTIONS = "ADMIN_GET_TRANSACTIONS";

// CATEGORY
export function adminGetCategories(payload) {
  return { type: ADMIN_GET_CATEGORIES, payload };
}
export function adminAddCategory(payload) {
  return { type: ADMIN_ADD_CATEGORY, payload };
}
export function adminUpdateCategory(payload) {
  return { type: ADMIN_UPDATE_CATEGORY, payload };
}
export function adminDeleteCategory(payload) {
  return { type: ADMIN_DELETE_CATEGORY, payload };
}

// SUBCATEGORY
export function adminGetSubCategories(payload) {
  return { type: ADMIN_GET_SUB_CATEGORIES, payload };
}
export function adminAddSubCategory(payload) {
  return { type: ADMIN_ADD_SUBCATEGORY, payload };
}
export function adminUpdateSubCategory(payload) {
  return { type: ADMIN_UPDATE_SUBCATEGORY, payload };
}
export function adminDeleteSubCategory(payload) {
  return { type: ADMIN_DELETE_SUBCATEGORY, payload };
}

// PRODUCT
export function adminGetProducts(payload) {
  return { type: ADMIN_GET_PRODUCTS, payload };
}
export function adminAddProduct(payload) {
  return { type: ADMIN_ADD_PRODUCT, payload };
}
export function adminUpdateProduct(payload) {
  return { type: ADMIN_UPDATE_PRODUCT, payload };
}
export function adminDeleteProduct(payload) {
  return { type: ADMIN_DELETE_PRODUCT, payload };
}

// USER
export function adminGetUsers(payload) {
  return { type: ADMIN_GET_USERS, payload };
}

// BRAND
export function adminGetBrands(payload) {
  return { type: ADMIN_GET_BRANDS, payload };
}
export function adminAddBrand(payload) {
  return { type: ADMIN_ADD_BRAND, payload };
}
export function adminUpdateBrand(payload) {
  return { type: ADMIN_UPDATE_BRAND, payload };
}
export function adminDeleteBrand(payload) {
  return { type: ADMIN_DELETE_BRAND, payload };
}

// FEATURED
export function adminGetFeatureds(payload) {
  return { type: ADMIN_GET_FEATUREDS, payload };
}
export function adminAddFeatured(payload) {
  return { type: ADMIN_ADD_FEATURED, payload };
}
export function adminUpdateFeatured(payload) {
  return { type: ADMIN_UPDATE_FEATURED, payload };
}
export function adminDeleteFeatured(payload) {
  return { type: ADMIN_DELETE_FEATURED, payload };
}

// PROMOTION
export function adminGetPromotions(payload) {
  return { type: ADMIN_GET_PROMOTIONS, payload };
}
export function adminAddPromotion(payload) {
  return { type: ADMIN_ADD_PROMOTION, payload };
}
export function adminUpdatePromotion(payload) {
  return { type: ADMIN_UPDATE_PROMOTION, payload };
}
export function adminDeletePromotion(payload) {
  return { type: ADMIN_DELETE_PROMOTION, payload };
}

// ORDER
export function adminGetOrders(payload) {
  return { type: ADMIN_GET_ORDERS, payload };
}
export function adminUpdateOrder(payload) {
  return { type: ADMIN_UPDATE_ORDER, payload };
}
export function adminDeleteOrder(payload) {
  return { type: ADMIN_DELETE_ORDER, payload };
}

// INVENTORY
export function adminGetInventory(payload) {
  return {
    type: ADMIN_GET_INVENTORY,
    payload,
  };
}
export function adminUpdateInventory(payload) {
  return {
    type: ADMIN_UPDATE_INVENTORY,
    payload,
  };
}
export function adminGetInventoryItem(payload) {
  return {
    type: ADMIN_GET_INVENTORY_ITEM,
    payload,
  };
}
// PROFIT
export function adminGetProfitProducts(payload) {
  return {
    type: ADMIN_GET_PROFIT_PRODUCTS,
    payload,
  };
}
export function adminGetProfitOrders(payload) {
  return {
    type: ADMIN_GET_PROFIT_ORDERS,
    payload,
  };
}
export function adminGetProfitUsers(payload) {
  return {
    type: ADMIN_GET_PROFIT_USERS,
    payload,
  };
}

//CONTACT-FORMS
export function adminGetContactForms(payload) {
  return {
    type: ADMIN_GET_CONTACT_FORMS,
    payload,
  };
}

export function adminDeleteContactForm(payload) {
  return {
    type: ADMIN_DELETE_CONTACT_FORM,
    payload,
  };
}
// BLOGS
export function adminGetBlogs(payload) {
  return {
    type: ADMIN_GET_BLOGS,
    payload,
  };
}
export function adminAddBlog(payload) {
  return {
    type: ADMIN_ADD_BLOG,
    payload,
  };
}
export function adminUpdateBlog(payload) {
  return {
    type: ADMIN_UPDATE_BLOG,
    payload,
  };
}
export function adminDeleteBlog(payload) {
  return {
    type: ADMIN_DELETE_BLOG,
    payload,
  };
}

// SLIDER
export function adminGetSliders(payload) {
  return { type: ADMIN_GET_SLIDERS, payload };
}
export function adminAddSlider(payload) {
  return { type: ADMIN_ADD_SLIDER, payload };
}
export function adminUpdateSlider(payload) {
  return { type: ADMIN_UPDATE_SLIDER, payload };
}
export function adminDeleteSlider(payload) {
  return { type: ADMIN_DELETE_SLIDER, payload };
}

// TRANSACTION
export function adminGetTransactions(payload) {
  return { type: ADMIN_GET_TRANSACTIONS, payload };
}

//ERROR LOGS
export const ADMIN_GET_ERROR_LOGS = "ADMIN_GET_ERROR_LOGS";
export const ADMIN_DELETE_ERROR_LOG = "ADMIN_DELETE_ERROR_LOG";
export function adminGetErrorLogs(payload) {
  return {
    type: ADMIN_GET_ERROR_LOGS,
    payload,
  };
}
export function adminDeleteErrorLog(payload) {
  return {
    type: ADMIN_DELETE_ERROR_LOG,
    payload,
  };
}

// REVIEWS

export const ADMIN_GET_REVIEWS = "ADMIN_GET_REVIEWS";
export const ADMIN_ADD_REVIEW = "ADMIN_ADD_REVIEW";
export const ADMIN_UPDATE_REVIEW = "ADMIN_UPDATE_REVIEW";
export const ADMIN_DELETE_REVIEW = "ADMIN_DELETE_REVIEW";
export function adminGetReviews(payload) {
  return {
    type: ADMIN_GET_REVIEWS,
    payload,
  };
}
export function adminAddReview(payload) {
  return {
    type: ADMIN_ADD_REVIEW,
    payload,
  };
}
export function adminUpdateReview(payload) {
  return {
    type: ADMIN_UPDATE_REVIEW,
    payload,
  };
}
export function adminDeleteReview(payload) {
  return {
    type: ADMIN_DELETE_REVIEW,
    payload,
  };
}

export const ADMIN_GET_TESTIMONIALS = "ADMIN_GET_TESTIMONIALS";
export const ADMIN_ADD_TESTIMONIAL = "ADMIN_ADD_TESTIMONIAL";
export const ADMIN_UPDATE_TESTIMONIAL = "ADMIN_UPDATE_TESTIMONIAL";
export const ADMIN_DELETE_TESTIMONIAL = "ADMIN_DELETE_TESTIMONIAL";
export function adminGetTestimonials(payload) {
  return {
    type: ADMIN_GET_TESTIMONIALS,
    payload,
  };
}
export function adminAddTestimonial(payload) {
  return {
    type: ADMIN_ADD_TESTIMONIAL,
    payload,
  };
}
export function adminUpdateTestimonial(payload) {
  return {
    type: ADMIN_UPDATE_TESTIMONIAL,
    payload,
  };
}
export function adminDeleteTestimonial(payload) {
  return {
    type: ADMIN_DELETE_TESTIMONIAL,
    payload,
  };
}
