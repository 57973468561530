import { GET_PROFILE, LOGIN_USER } from "../actions/userActions";

const initialState = {
  profile: {
    name: "",
    avatar: "avatars/6.jpg",
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
      };
    default:
      return state;
  }
}
