// import { consoleLog } from "src/utils/helper";
import {
  ADMIN_GET_CATEGORIES,
  ADMIN_GET_SUB_CATEGORIES,
  ADMIN_GET_PRODUCTS,
  ADMIN_GET_USERS,
  ADMIN_GET_BRANDS,
  ADMIN_GET_ORDERS,
  ADMIN_UPDATE_ORDER,
  ADMIN_DELETE_ORDER,
  ADMIN_GET_FEATUREDS,
  ADMIN_GET_PROMOTIONS,
  ADMIN_ADD_CATEGORY,
  ADMIN_UPDATE_CATEGORY,
  ADMIN_DELETE_CATEGORY,
  ADMIN_ADD_SUBCATEGORY,
  ADMIN_UPDATE_SUBCATEGORY,
  ADMIN_DELETE_SUBCATEGORY,
  ADMIN_ADD_BRAND,
  ADMIN_UPDATE_BRAND,
  ADMIN_DELETE_BRAND,
  ADMIN_ADD_FEATURED,
  ADMIN_ADD_PROMOTION,
  ADMIN_UPDATE_PROMOTION,
  ADMIN_DELETE_PROMOTION,
  ADMIN_ADD_PRODUCT,
  ADMIN_UPDATE_PRODUCT,
  ADMIN_DELETE_PRODUCT,
  ADMIN_UPDATE_FEATURED,
  ADMIN_DELETE_FEATURED,
  ADMIN_GET_INVENTORY,
  ADMIN_UPDATE_INVENTORY,
  ADMIN_GET_PROFIT_PRODUCTS,
  ADMIN_GET_PROFIT_ORDERS,
  ADMIN_GET_PROFIT_USERS,
  ADMIN_GET_CONTACT_FORMS,
  ADMIN_DELETE_CONTACT_FORM,
  ADMIN_GET_BLOGS,
  ADMIN_ADD_BLOG,
  ADMIN_UPDATE_BLOG,
  ADMIN_DELETE_BLOG,
  ADMIN_GET_SLIDERS,
  ADMIN_ADD_SLIDER,
  ADMIN_UPDATE_SLIDER,
  ADMIN_DELETE_SLIDER,
  ADMIN_GET_TRANSACTIONS,
  ADMIN_GET_ERROR_LOGS,
  ADMIN_DELETE_ERROR_LOG,
  ADMIN_GET_REVIEWS,
  ADMIN_UPDATE_REVIEW,
  ADMIN_DELETE_REVIEW,
  ADMIN_ADD_REVIEW,
  ADMIN_GET_TESTIMONIALS,
  ADMIN_ADD_TESTIMONIAL,
  ADMIN_UPDATE_TESTIMONIAL,
  ADMIN_DELETE_TESTIMONIAL,
} from "../actions/adminActions";

const initialState = {
  categories: [],
  subcategories: [],
  products: [],
  brands: [],
  featureds: [],
  promotions: [],
  users: [],
  orders: [],
  inventory: [],
  profit: {
    products: [],
    orders: [],
    users: [],
  },
  contactForm: [],
  blogs: [],
  sliders: [],
  transactions: [],
  errorlogs: [],
  reviews: [],
  testimonials: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ADMIN_ADD_CATEGORY: {
      let categories = [...state.categories];
      categories.push(action.payload);
      return {
        ...state,
        categories: categories,
      };
    }
    case ADMIN_UPDATE_CATEGORY: {
      let categories = [...state.categories];
      let index = categories.findIndex((cat) => cat._id === action.payload._id);
      categories[index] = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }
    case ADMIN_DELETE_CATEGORY: {
      let categories = [...state.categories];
      let index = categories.findIndex((cat) => cat._id === action.payload);
      categories.splice(index, 1);
      return {
        ...state,
        categories: categories,
      };
    }
    // SUB CATEGORY
    case ADMIN_GET_SUB_CATEGORIES:
      return {
        ...state,
        subcategories: action.payload,
      };
    case ADMIN_ADD_SUBCATEGORY: {
      let categories = [...state.categories];
      let subcategories = [...state.subcategories];
      if (subcategories.length > 0) subcategories.push(action.payload);
      const cat_id =
        action.payload &&
        action.payload.category &&
        action.payload.category._id;
      if (cat_id) {
        const index = categories.findIndex((cat) => cat._id === cat_id);
        const category = categories[index];
        if (category) {
          if (category.subcategory && Array.isArray(category.subcategory)) {
            category.subcategory.push(action.payload);
            categories[index] = category;
          }
        }
      }
      return {
        ...state,
        categories: categories,
        subcategories: subcategories,
      };
    }
    case ADMIN_UPDATE_SUBCATEGORY: {
      let subcategories = [...state.subcategories];
      let index = subcategories.findIndex(
        (cat) => cat._id === action.payload._id
      );
      subcategories[index] = action.payload;

      const categories = [...state.categories];
      const cat_id =
        action.payload &&
        action.payload.category &&
        action.payload.category._id;
      if (cat_id) {
        const index = categories.findIndex((cat) => cat._id === cat_id);
        const category = categories[index];
        if (category) {
          if (category.subcategory && Array.isArray(category.subcategory)) {
            const sub_index = category.subcategory.findIndex(
              (item) => item._id === action.payload._id
            );
            category.subcategory[sub_index] = action.payload;
            categories[index] = category;
          }
        }
      }
      return {
        ...state,
        categories: categories,
        subcategories: subcategories,
      };
    }
    case ADMIN_DELETE_SUBCATEGORY: {
      let subcategories = [...state.subcategories];
      let index = subcategories.findIndex(
        (cat) => cat._id === action.payload._id
      );
      subcategories.splice(index, 1);

      const categories = [...state.categories];
      const cat_id = action.payload && action.payload.category;
      if (cat_id) {
        const index = categories.findIndex((cat) => cat._id === cat_id);
        const category = categories[index];
        if (category) {
          if (category.subcategory && Array.isArray(category.subcategory)) {
            const sub_index = category.subcategory.findIndex(
              (item) => item._id === action.payload._id
            );
            category.subcategory.splice(sub_index, 1);
            categories[index] = category;
          }
        }
      }
      return {
        ...state,
        // categories: categories,
        subcategories: subcategories,
      };
    }
    // PRODUCT
    case ADMIN_GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case ADMIN_ADD_PRODUCT: {
      let products = [...state.products];
      products.push(action.payload);
      return {
        ...state,
        products: products,
      };
    }
    case ADMIN_UPDATE_PRODUCT: {
      let products = [...state.products];
      let index = products.findIndex((cat) => cat._id === action.payload._id);
      products[index] = action.payload;
      return {
        ...state,
        products: products,
      };
    }
    case ADMIN_DELETE_PRODUCT: {
      let products = [...state.products];
      let index = products.findIndex((cat) => cat._id === action.payload);
      products.splice(index, 1);
      return {
        ...state,
        products: products,
      };
    }
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ADMIN_GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case ADMIN_ADD_BRAND: {
      let brands = [...state.brands];
      brands.push(action.payload);
      return {
        ...state,
        brands: brands,
      };
    }
    case ADMIN_UPDATE_BRAND: {
      let brands = [...state.brands];
      let index = brands.findIndex((cat) => cat._id === action.payload._id);
      brands[index] = action.payload;
      return {
        ...state,
        brands: brands,
      };
    }
    case ADMIN_DELETE_BRAND: {
      let brands = [...state.brands];
      let index = brands.findIndex((cat) => cat._id === action.payload);
      brands.splice(index, 1);
      return {
        ...state,
        brands: brands,
      };
    }
    case ADMIN_GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case ADMIN_UPDATE_ORDER: {
      let orders = [...state.orders];
      let index = orders.findIndex((cat) => cat._id === action.payload._id);
      orders[index] = action.payload;
      return {
        ...state,
        orders: orders,
      };
    }
    case ADMIN_DELETE_ORDER: {
      let orders = [...state.orders];
      let index = orders.findIndex((cat) => cat._id === action.payload);
      orders.splice(index, 1);
      return {
        ...state,
        orders: orders,
      };
    }
    case ADMIN_GET_FEATUREDS:
      return {
        ...state,
        featureds: action.payload,
      };
    case ADMIN_ADD_FEATURED: {
      let featureds = [...state.featureds];
      featureds.push(action.payload);
      return {
        ...state,
        featureds: featureds,
      };
    }
    case ADMIN_UPDATE_FEATURED: {
      let featureds = [...state.featureds];
      let index = featureds.findIndex((cat) => cat._id === action.payload._id);
      featureds[index] = action.payload;
      return {
        ...state,
        featureds: featureds,
      };
    }
    case ADMIN_DELETE_FEATURED: {
      let featureds = [...state.featureds];
      let index = featureds.findIndex((cat) => cat._id === action.payload);
      featureds.splice(index, 1);
      return {
        ...state,
        featureds: featureds,
      };
    }
    case ADMIN_GET_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload,
      };
    case ADMIN_ADD_PROMOTION: {
      let promotions = [...state.promotions];
      promotions.push(action.payload);
      return {
        ...state,
        promotions: promotions,
      };
    }
    case ADMIN_UPDATE_PROMOTION: {
      let promotions = [...state.promotions];
      let index = promotions.findIndex((cat) => cat._id === action.payload._id);
      promotions[index] = action.payload;
      return {
        ...state,
        promotions: promotions,
      };
    }
    case ADMIN_DELETE_PROMOTION: {
      let promotions = [...state.promotions];
      let index = promotions.findIndex((cat) => cat._id === action.payload);
      promotions.splice(index, 1);
      return {
        ...state,
        promotions: promotions,
      };
    }
    case ADMIN_GET_INVENTORY: {
      return {
        ...state,
        inventory: action.payload,
      };
    }
    case ADMIN_UPDATE_INVENTORY: {
      let inventory = [...state.inventory];
      let index = inventory.findIndex((cat) => cat._id === action.payload._id);
      inventory[index] = action.payload;
      return {
        ...state,
        inventory: inventory,
      };
    }
    case ADMIN_GET_PROFIT_PRODUCTS: {
      return {
        ...state,
        profit: {
          ...state.profit,
          products: action.payload,
        },
      };
    }
    case ADMIN_GET_PROFIT_ORDERS: {
      return {
        ...state,
        profit: {
          ...state.profit,
          orders: action.payload,
        },
      };
    }
    case ADMIN_GET_PROFIT_USERS: {
      return {
        ...state,
        profit: {
          ...state.profit,
          users: action.payload,
        },
      };
    }
    case ADMIN_GET_CONTACT_FORMS: {
      return {
        ...state,
        contactForm: action.payload,
      };
    }
    case ADMIN_DELETE_CONTACT_FORM: {
      let contactForm = [...state.contactForm];
      let index = contactForm.findIndex((cat) => cat._id === action.payload);
      contactForm.splice(index, 1);
      return {
        ...state,
        contactForm: contactForm,
      };
    }

    case ADMIN_GET_BLOGS: {
      return {
        ...state,
        blogs: action.payload,
      };
    }

    case ADMIN_ADD_BLOG: {
      let blogs = [...state.blogs];
      blogs.push(action.payload);
      return {
        ...state,
        blogs: blogs,
      };
    }
    case ADMIN_UPDATE_BLOG: {
      let blogs = [...state.blogs];
      let index = blogs.findIndex((cat) => cat._id === action.payload._id);
      blogs[index] = action.payload;
      return {
        ...state,
        blogs: blogs,
      };
    }
    case ADMIN_DELETE_BLOG: {
      let blogs = [...state.blogs];
      let index = blogs.findIndex((cat) => cat._id === action.payload);
      blogs.splice(index, 1);
      return {
        ...state,
        blogs: blogs,
      };
    }
    // SLIDER

    case ADMIN_GET_SLIDERS:
      return {
        ...state,
        sliders: action.payload,
      };
    case ADMIN_ADD_SLIDER: {
      let sliders = [...state.sliders];
      sliders.push(action.payload);
      return {
        ...state,
        sliders: sliders,
      };
    }
    case ADMIN_UPDATE_SLIDER: {
      let sliders = [...state.sliders];
      let index = sliders.findIndex((cat) => cat._id === action.payload._id);
      sliders[index] = action.payload;
      return {
        ...state,
        sliders: sliders,
      };
    }
    case ADMIN_DELETE_SLIDER: {
      let sliders = [...state.sliders];
      let index = sliders.findIndex((cat) => cat._id === action.payload);
      sliders.splice(index, 1);
      return {
        ...state,
        sliders: sliders,
      };
    }
    case ADMIN_GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case ADMIN_GET_ERROR_LOGS:
      return {
        ...state,
        errorlogs: action.payload,
      };
    case ADMIN_DELETE_ERROR_LOG: {
      let errorlogs = [...state.errorlogs];
      let index = errorlogs.findIndex((log) => log._id === action.payload);
      errorlogs.splice(index, 1);
      return {
        ...state,
        errorlogs: errorlogs,
      };
    }
    case ADMIN_GET_REVIEWS: {
      return {
        ...state,
        reviews: action.payload,
      };
    }
    case ADMIN_ADD_REVIEW: {
      let reviews = [...state.reviews];
      reviews.push(action.payload);
      return {
        ...state,
        reviews: reviews,
      };
    }
    case ADMIN_UPDATE_REVIEW: {
      let reviews = [...state.reviews];
      let index = reviews.findIndex((data) => data._id === action.payload._id);
      reviews[index] = action.payload;
      return {
        ...state,
        reviews: reviews,
      };
    }
    case ADMIN_DELETE_REVIEW: {
      let reviews = [...state.reviews];
      let index = reviews.findIndex((data) => data._id === action.payload);
      reviews.splice(index, 1);
      return {
        ...state,
        reviews: reviews,
      };
    }
    case ADMIN_GET_TESTIMONIALS: {
      return {
        ...state,
        testimonials: action.payload,
      };
    }
    case ADMIN_ADD_TESTIMONIAL: {
      let testimonials = [...state.testimonials];
      testimonials.push(action.payload);
      return {
        ...state,
        testimonials: testimonials,
      };
    }
    case ADMIN_UPDATE_TESTIMONIAL: {
      let testimonials = [...state.testimonials];
      let index = testimonials.findIndex(
        (data) => data._id === action.payload._id
      );
      testimonials[index] = action.payload;
      return {
        ...state,
        testimonials: testimonials,
      };
    }
    case ADMIN_DELETE_TESTIMONIAL: {
      let testimonials = [...state.testimonials];
      let index = testimonials.findIndex((data) => data._id === action.payload);
      testimonials.splice(index, 1);
      return {
        ...state,
        testimonials: testimonials,
      };
    }

    default:
      return state;
  }
}
