import { combineReducers } from "redux";

import navReducer from "./navReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";
import superAdminReducer from "./superAdminReducer";

const appReducer = combineReducers({
  user: userReducer,
  nav: navReducer,
  admin: adminReducer,
  superadmin: superAdminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
