import {
  SUPERADMIN_GET_USERS,
  SUPERADMIN_ADD_USER,
  SUPERADMIN_GET_BRANCHES,
  SUPERADMIN_ADD_BRANCH,
  SUPERADMIN_UPDATE_BRANCH,
  SUPERADMIN_DELETE_BRANCH,
  SUPERADMIN_UPDATE_USER,
  SUPERADMIN_DELETE_USER,
} from "../actions/superAdminActions";

const initialState = {
  branches: [],
  users: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SUPERADMIN_GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SUPERADMIN_ADD_USER: {
      let users = [...state.users];
      users.push(action.payload);
      return {
        ...state,
        users: users,
      };
    }
    case SUPERADMIN_UPDATE_USER: {
      let users = [...state.users];
      let index = users.findIndex((cat) => cat._id === action.payload._id);
      users[index] = action.payload;
      return {
        ...state,
        users: users,
      };
    }
    case SUPERADMIN_DELETE_USER: {
      let users = [...state.users];
      let index = users.findIndex((cat) => cat._id === action.payload);
      users.splice(index, 1);
      return {
        ...state,
        users: users,
      };
    }
    case SUPERADMIN_GET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case SUPERADMIN_ADD_BRANCH: {
      let branches = [...state.branches];
      branches.push(action.payload);
      return {
        ...state,
        branches: branches,
      };
    }
    case SUPERADMIN_UPDATE_BRANCH: {
      let branches = [...state.branches];
      let index = branches.findIndex((cat) => cat._id === action.payload._id);
      branches[index] = action.payload;
      return {
        ...state,
        branches: branches,
      };
    }
    case SUPERADMIN_DELETE_BRANCH: {
      let branches = [...state.branches];
      let index = branches.findIndex((cat) => cat._id === action.payload);
      branches.splice(index, 1);
      return {
        ...state,
        branches: branches,
      };
    }
    default:
      return state;
  }
}
