export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_PROFILE = "GET_PROFILE";

export function loginUser(payload) {
  return { type: LOGIN_USER, payload };
}
export function logoutUser() {
  return { type: LOGOUT_USER };
}
export function getProfile(payload) {
  return { type: GET_PROFILE, payload };
}
